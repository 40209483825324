import React from "react"
import Fade from 'react-reveal/Fade'

interface CopyrightProps {
    copyright: Array<string>
}

const Copyright: React.FC<CopyrightProps> = (props) => {
    return (
        <Fade bottom>
            <div className='copyright'>
                <p>&#169; {props.copyright[0]}</p>
                <p>{props.copyright[1]}</p>
            </div>
        </Fade>
    )
}

export default Copyright