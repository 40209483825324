import React from 'react'
import './Menu.css'
import Fade from 'react-reveal/Fade'

interface PropsPhones2 {
    phones2: Array<string>
}

const List: React.FC<PropsPhones2> = (props) => {
    return (
        <Fade right>
            <ul className='listOfPhones'>
                {props.phones2.map(elem => {
                    return (
                        <li key={Math.random()} color='#000'>
                            <a href={elem.includes('mail') ? ('mailto:' + elem) : ('tel:' + elem)} >
                                {elem}
                            </a>
                        </li>
                    )
                })}
            </ul>
        </Fade>
    )            
}

export default List