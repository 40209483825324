import {useState} from 'react'
import './App.css'
import Footer from './CommonComp/Footer/Footer'
import Menu from './CommonComp/Menu/Menu'
import Accreditation from './pages/Accreditation/Accreditation'
import Contacts from './pages/Contacts/Contacts'
import MainPage from './pages/Main/MainPage'
import Services from './pages/Services/Services'
import { BrowserRouter, Route } from 'react-router-dom'


function App() {
  const [stateMenu, changeMenu] = useState([
    {id: 1, isChosen: true, title: 'Главная', href: '/'},
    {id: 2, isChosen: false, title: 'Услуги', href: '/services'},
    {id: 3, isChosen: false, title: 'Аккредитация', href: '/accreditation'},
    {id: 4, isChosen: false, title: 'Контакты', href: '/contacts'},
  ])

  const phonesAndMail = ['+7 929 771 20 05', '+7 961 052 56 00', 'pozlab64@mail.ru']

  const copyright = ['ООО "Пожарная лаборатория"', 'made by Levochkin Ruslan']

  // const [chosenPage, changeChosenPage] = useState(<MainPage />)

function changePage(id: string) {
  changeMenu(
    stateMenu.map(elem1 => {
      let newId: number = Number(id);
      if(elem1.id === newId) {
        elem1.isChosen = true
      } 
      else {
        elem1.isChosen = false
      }
      return elem1
    })
  ) 
  
}

  return (
      <div>
        <BrowserRouter>
          <Menu menuItems={stateMenu} phones={phonesAndMail} changePage={changePage} />
            <Route component={MainPage} exact strict path='/' />
            <Route component={Services} path='/services' />
            <Route component={Accreditation} path='/accreditation' />
            <Route component={Contacts} path='/contacts' />   
          <Footer contacts={phonesAndMail} copyright={copyright} />
        </BrowserRouter>
      </div>
  )
}

export default App;
