import React from "react"
import '../../index.css'

const QuestButton: React.FC = () => {
    return (
        <a href='https://forms.gle/AroJZq8z7SQtbZqt7' target="_blank">
            <button className='questBtn' type='button' >
                Задать вопрос
            </button>
        </a>
    )

}

export default QuestButton