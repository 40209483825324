import React from "react"

const TitleServices: React.FC = () => {
    return (
        <div className='titleServices'>
            Широкий спектр услуг
        </div>
    )
}

export default TitleServices