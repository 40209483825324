import React from "react"
import Zoom from 'react-reveal/Zoom'

const OrderList: React.FC = () => {
    return (
        <Zoom cascade>
            <div className='orderList'>
                    <p>
                        1Бесплатная консультация
                    </p>
                    <p>
                        2Подписание договора
                    </p>
                    <p>
                        3Проведение работ
                    </p>
                    <p>
                        4Получение сертификата
                    </p>
            </div>
        </Zoom>
    )
}

export default OrderList