import React from "react"

interface Phone2Props {
    phone2: string
}

const Phone2: React.FC<Phone2Props> = (props) => {
    return (
        <p className='phone2'>
            {props.phone2}
        </p>
    )
}

export default Phone2