import React from "react"
import './Footer.css'
import Phone1 from "./Phone1"
import Phone2 from "./Phone2"
import Mail from "./Mail"
import Fade from 'react-reveal/Fade'

interface PhonesState {
    contacts: Array<string>
}

const Phones: React.FC<PhonesState> = (props) => {
    return (
        <div className='contacts'>
            <Fade left>
                <p className='contTitle'>
                    Контакты
                </p>
                <Phone1 phone1={props.contacts[0]} />
                <Phone2 phone2={props.contacts[1]} />
                <Mail mail={props.contacts[2]} />
            </Fade>
        </div>
    )
}

export default Phones