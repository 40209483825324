import React from "react"
import OrderList from "./OrderList"
import Fade from 'react-reveal/Fade'

const OrderOfServicesHandling: React.FC = () => {
    return (
        <div>
            <Fade left>
                <p className='orderOfservicesTitle'>
                    Порядок оказания услуг
                </p>
            </Fade>
            <OrderList />
        </div>
    )
}

export default OrderOfServicesHandling