import React from "react"
import '../../index.css'

interface TableServicesProps {
    id: number
    name: JSX.Element
    cost: JSX.Element
    href: string
}

const ServicesTableElem: React.FC<TableServicesProps> = (props) => {
    return (
        <tr>
            <td className='numbersCol'> 
                {props.id} 
            </td>
            <td> 
                {props.name} 
            </td>
            <td> 
                {props.cost} 
            </td>
            <td>
                <a href={props.href} target="_blank" >
                    <button type='button' className='btn' id={String(props.id)} >
                        Заполнить заявку
                    </button>
                </a>
            </td>
        </tr>
    )
}

export default ServicesTableElem