import React from "react"
import './Contacts.css'

interface PhonesAndMail {
    phone1: string
    phone2: string
    mail: string
}

const ContactsFirstLine: React.FC<PhonesAndMail> = (props) => {
    return (
            <div className='firstLine'>
                <div>
                    <p>
                        Генеральный директор:
                    </p>
                    Левочкин Тимур Александрович, действующий на основании Устава
                </div>
                <div>
                    <p>
                        Телефон:
                    </p>
                    {props.phone1} 
                    <br />
                    {props.phone2}
                </div>
                <div>
                    <p>
                        Электронная почта:
                    </p>
                    {props.mail}
                </div>
            </div>
    )
}

export default ContactsFirstLine