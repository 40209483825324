import React from "react"
import ServicesFirstLine from "./ServicesFirstLine"
import ServicesSecondLine from "./ServicesSecondLine"
import TitleServices from "./TitleServices"
import Zoom from 'react-reveal/Zoom'

const ServicesMain: React.FC = () => {
    return (
        <Zoom clear>
            <div className='blockServices'>
                <TitleServices />
                <ServicesFirstLine />
                <ServicesSecondLine />
            </div>
        </Zoom>
    )
}

export default ServicesMain