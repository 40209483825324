import React from "react"

interface Image {
    imgBig: string
    imgSmall: string
    text: string
    id: number
    setActiveModal: Function
    changeImage: Function
    check: string
}

const Photo: React.FC<Image> = (props) => {
    return (
        <div className='photos'>
            <div className='gradient' onClick={() => {
                props.setActiveModal(true)
                props.changeImage(props.imgBig)
                console.log(props.check)
            }}>
                <img src={props.imgSmall} alt="" />
            </div>
            <p>
                {props.text}
            </p>
        </div>
    )
}

export default Photo