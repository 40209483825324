import React from 'react'
import './Menu.css'
import MenuItem from './MenuItem'

interface IndexOfPage {
    menuItems: Array<any>
    changePage: Function
}

const MenuBar: React.FC<IndexOfPage> = (props) => {
    return (
        <div className='menuBar'>
            {props.menuItems.map(elem => {
                return (
                    <MenuItem 
                        title={elem.title} 
                        state={elem.isChosen} 
                        key={elem.id} 
                        id={elem.id} 
                        onChange={props.changePage} 
                        href={elem.href} 
                    />
                )
            })}
        </div>
    )
}

export default MenuBar