import React from "react"

const ServicesSecondLine: React.FC = () => {
    return (
        <div className='servicesSecondLine'>
            <p>
                Консультирование, в том числе сопровождение проверок в области пожарной безопасности
            </p>
        </div>
    )
}

export default ServicesSecondLine