import React from "react"
import Phones from "./Phones"
import Questions from './Questions'

interface TopState {
    contacts: Array<string>
    copyright: Array<string>
}

const Top: React.FC<TopState> = (props) => {
    return (
        <div className='topFooter'>
            <Phones contacts={props.contacts} />
            <Questions />
        </div>
    )
}

export default Top