import React, { useState } from "react"
import imgSmall1 from  '../../assets/images/img1.jpg'
import imgBig1 from '../../assets/images/img1b.jpg'
import imgSmall2 from  '../../assets/images/img2.jpg'
import imgBig2 from '../../assets/images/img2b.jpg'
import imgSmall3 from  '../../assets/images/img3.jpg'
import imgBig3 from '../../assets/images/img3b.jpg'
import imgSmall4 from  '../../assets/images/img4.jpg'
import imgBig4 from '../../assets/images/img4b.jpg'
import imgSmall5 from  '../../assets/images/img5.jpg'
import imgBig5 from '../../assets/images/img5b.jpg'
import './Accreditation.css'
import Photo from "./Photo"
import Modal from "./ImagesModal/Modal"
import Zoom from 'react-reveal/Zoom'

const Accreditation: React.FC = () => {

    const [modalImgActive, setModalActive] = useState(false)

    const [chosenImage, changeImage] = useState(imgBig1)

    const allImages = 
        [
            {id: 1, smallImage: imgSmall1, bigImage: imgBig1, text: 'Свидетельство об аккредитации экспертной организации'},
            {id: 2, smallImage: imgSmall2, bigImage: imgBig2, text: 'Аттестат компетентности испытателя'},
            {id: 3, smallImage: imgSmall3, bigImage: imgBig3, text: 'Область аттестации испытателя'},
            {id: 4, smallImage: imgSmall4, bigImage: imgBig4, text: 'Аттестат компетентности эксперта-аудитора'},
            {id: 5, smallImage: imgSmall5, bigImage: imgBig5, text: 'Область аттестации эксперта-аудитора'},
        ]

    return (
        <div>
            <div className='accreditation'>
                {allImages.map(elem => {
                    return (
                        <Zoom clear>
                            <Photo 
                                imgBig={elem.bigImage} 
                                imgSmall={elem.smallImage} 
                                text={elem.text} 
                                setActiveModal={setModalActive} 
                                id={elem.id} 
                                changeImage={changeImage} 
                                check={chosenImage} 
                                key={elem.id} 
                            />
                        </Zoom>
                    )
                })}
            </div>
            <Zoom cascade>
                <div className='addition'>
                    Приложение <a href="./Приложение ОБЛАСТЬ ОЦЕНКИ СООТВЕТСТВИЯ ЭО.pdf" download title='Скачать документ'>ОБЛАСТЬ ОЦЕНКИ СООТВЕТСТВИЯ ЭО</a>
                </div>
            </Zoom>
            <Modal 
                active={modalImgActive} 
                setActive={setModalActive} 
                imgBig={chosenImage} 
            />
        </div>
    )
}

export default Accreditation