import React from "react"

const QuestText: React.FC = () => {
    return (
        <p className='questText'>
            Задайте их нам и получите ответ в течение 2-3-х рабочих дней
        </p>
    )
}

export default QuestText