import React from "react"
import './MainPage.css'
import Zoom from 'react-reveal/Zoom'

const Text: React.FC = () => {
    return (
        <div className='mainText'>
            <p className='aboutUs'>
                О нас
            </p>
            <Zoom clear>
                <p className='text'>
                    ООО “Пожарная лаборатория” возникло в 2019 году и уже более двух лет пользуется завидной популярностью у своих клиентов. Компания может представить Вам широкий спектр услуг по пожарной обработке зданий.
                    <br />
                    <br />
                    Среди конкурентов мы выделяемся профессионализмом сотрудников, исключительным доверием клиентов, высококвалифицированной работой и неизменно качественным оказанием услуг.
                </p>
            </Zoom>
        </div>
    )
}

export default Text