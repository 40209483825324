import React from "react"
import Text from "./Text"
import ServicesMain from "./ServicesMain";
import OrderOfServicesHandling from "./OrderOfServicesHandling"

const MainPage: React.FC = () => {
    return (
        <div>
            <Text />
            <ServicesMain />
            <OrderOfServicesHandling />
        </div>
    )
}

export default MainPage