import React from "react"
import MailOutlineIcon from '@material-ui/icons/MailOutline'

interface MailProps {
    mail: string
}

const Mail: React.FC<MailProps> = (props) => {
    return (
        <div className='phones'>
            <MailOutlineIcon fontSize='small' />
            <p> 
                {props.mail}
            </p>
        </div>
    )
}

export default Mail