import React from "react"
import ServicesTableElem from "./ServicesTableElem"
import './Services.css'

interface TableServicesProps {
    servicesList: Array<any>
}

const ServicesTable: React.FC<TableServicesProps> = (props) => {
    return (
        <table className='servicesTable'>
            <tr className='tableServicesFirstLine'>
                <td className='numbers'>
                    № п/п
                </td>
                <td>
                    Наименование услуги
                </td>
                <td>
                    Цена без НДС, руб.
                </td>
                <td></td>
            </tr>
            {props.servicesList.map(elem => {
                return <ServicesTableElem 
                            key={elem.id} 
                            id={elem.id} 
                            name={elem.name} 
                            cost={elem.cost} 
                            href={elem.href} 
                        />
            })}
        </table>
    )
}

export default ServicesTable