import React from "react"
import Copyright from "./Copyright"
import './Footer.css'
import Top from './Top'

interface Contacts {
    contacts: Array<string>
    copyright: Array<string>
}

const Footer: React.FC<Contacts> = (props) => {
    return (
        <div className='footerBlack'>
            <div className='footerGradient' />
            <footer className='footer'>
                <Top contacts={props.contacts} copyright={props.copyright} />
                <Copyright copyright={props.copyright} />
            </footer>
        </div>
    )
}

export default Footer