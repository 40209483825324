import React from "react"
import './Modal.css'

interface PictureModalProps {
    imgBig: string
    active: boolean
    setActive: Function
}

const Modal: React.FC<PictureModalProps> = (props) => {
    return (
        <div 
            className={props.active ? 'modal active' : 'modal'} 
            onClick={() => {
                props.setActive(false)
            }}
        >
            <img 
                src={String(props.imgBig)} 
                alt="" 
                className={props.active ? 'modalContent active' : 'modalContent'} 
                onClick={e => {
                    e.stopPropagation()
                }} 
            />
        </div>
    )
}

export default Modal