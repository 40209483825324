import React from 'react'
import Fade from 'react-reveal/Fade'

const Quote: React.FC = () => {
    return (
        <Fade left cascade big>
            <div className='quote'>
                <p id='i1'>
                    Предупрежден - 
                </p>
                <p id='i2'>
                    значит вооружен
                </p>
                <p id='i3'>
                    Латинская пословица
                </p>
            </div>
        </Fade>
    )
}

export default Quote