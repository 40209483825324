import React from "react"

interface ListElem {
    element: any
}

const ListElement: React.FC<ListElem> = (props) => {
    return (
        <div className='listElem'>
            <div className='listCircle' />
            <div className='listText'>
                {props.element.context}
            </div>
        </div>
    )
}

export default ListElement