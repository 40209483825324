import React from "react"
import './Contacts.css'
import ContactsFirstLine from "./ContactsFirstLine"
import ContactsSecondLine from "./ContactsSecondLine"

const Contacts: React.FC = () => {

    const phonesAndMail = [
        '+7 929 771 20 05', 
        '+7 961 052 56 00', 
        'pozlab64@mail.ru'
    ]

    return (
        <div>
            <ContactsFirstLine 
                phone1={phonesAndMail[0]} 
                phone2={phonesAndMail[1]} 
                mail={phonesAndMail[2]} 
            />
            <ContactsSecondLine />
        </div>
    )
}

export default Contacts