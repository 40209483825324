import React from "react"
import QuestButton from "./QuestButton"
import QuestText from "./QuestText"
import QuestTitle from './QuestTitle'
import Fade from 'react-reveal/Fade'

const Question: React.FC = () => {
    return (
        <div className='quest'>
            <Fade right>
                <QuestTitle />
                <QuestText />
                <QuestButton />
            </Fade>
        </div>
    )
}

export default Question