import React from "react"

const ServicesFirstLineLeft: React.FC = () => {
    return (
        <div className='servicesFirstLineLeft'>
            Оценка соответствия качества (состояния) огнезащитной обработки металлических и деревянных конструкций, оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности установок:
        </div>
    )
}

export default ServicesFirstLineLeft