import React from 'react'
import './Menu.css'
import MenuBar from './MenuBar'
import Quote from './Quote'
import Top from './Top'

interface WhichPage {
    menuItems: Array<any>
    changePage: Function
    phones: Array<string>
}

const Menu: React.FC<WhichPage> = (props) => {
    return (
        <header className='backgroundMenu'>
            <Top phones={props.phones} />
            <Quote />
            <MenuBar menuItems={props.menuItems} changePage={props.changePage} />
        </header>
    )            
}

export default Menu