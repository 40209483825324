import React from 'react'
import './Menu.css'
import logo from '../../assets/images/logo.png'
import Fade from 'react-reveal/Fade'

const Logo: React.FC = () => {
    return (
        <Fade left>
            <div className='logo'>
                <img src={logo} alt='' />
            </div>
        </Fade>
    )
}

export default Logo