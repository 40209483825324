import React from "react"
import './Contacts.css'
import Zoom from 'react-reveal/Zoom'

const ContactsSecondLine: React.FC = () => {
    return (
        <div className='secondLine'>
            <div>
                <p className='title'>
                    Реквизиты
                </p>
                <Zoom cascade clear>
                    <p>
                        ИНН: 6454118808
                    </p>
                    <p>
                        КПП: 645401001
                    </p>
                    <p>
                        Р/с 40702810500000011184
                    </p>
                    <p>
                        Банк: в отделение Саратов АО Банк «Агророс»,
                    </p>
                    <p>
                        К/с 30101810600000000772
                    </p>
                    <p>
                        БИК 046311772
                    </p>
                    <p>
                        ОГРН: 1196451007444, ОКПО: 36895780, ОКАТО: 63401380000, ОКТМО: 63701000001, ОКОГУ: 4210014, ОКФС: 16, ОКОПФ: 12300, ОКВЭД: 72.20.8
                    </p>
                    <p>
                        Юридический адрес: 410056, г. Саратов, ул. Ульяновская, д. 37/41 кв. 32
                    </p>
                </Zoom>
            </div>
        </div>
    )
}

export default ContactsSecondLine