import React from "react"
import './Services.css'
import ServicesTable from "./ServicesTable"

const Services: React.FC = () => {
    const allServices = [
        {id: 1, name: <p>Оценка соответствия качества (состояния) огнезащитной обработки металлических конструкций до 100 м<sup>2</sup></p>, cost: <p>1500; каждые последующие 100 м<sup>2</sup> + 150 руб</p>, href: 'https://forms.gle/Cey5jotTn8MiuTwr6'},

        {id: 2, name: <p>Оценка соответствия качества (состояния) огнезащитной обработки металлических конструкций до 1000 м<sup>2</sup></p>, cost: <p>1800</p>, href: 'https://forms.gle/Cey5jotTn8MiuTwr6'},

        {id: 3, name: <p>Оценка соответствия качества (состояния) огнезащитной обработки металлических конструкций до 2000 м<sup>2</sup></p>, cost: <p>3000</p>, href: 'https://forms.gle/Cey5jotTn8MiuTwr6'},

        {id: 4, name: <p>Оценка соответствия качества (состояния) огнезащитной обработки металлических конструкций до 3000 м<sup>2</sup></p>, cost: <p>3000</p>, href: 'https://forms.gle/Cey5jotTn8MiuTwr6'},

        {id: 5, name: <p>Оценка соответствия качества (состояния) огнезащитной обработки металлических конструкций до 4000 м<sup>2</sup></p>, cost: <p>4500; каждые последующие 1000 м<sup>2</sup> + 600 руб</p>, href: 'https://forms.gle/Cey5jotTn8MiuTwr6'},

        {id: 6, name: <p>Оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности внутреннего противопожарного водопровода (1 кран)</p>, cost: <p>2000</p>, href: 'https://forms.gle/crPDNTVvo7xWdHBk6'},

        {id: 7, name: <p>Оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности источников наружного противопожарного водоснабжения (1 гидрант)</p>, cost: <p>6000</p>, href: 'https://forms.gle/zniPZTkDNsHRbMtbA'},

        {id: 8, name: <p>Оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности систем дымоудаления и подпора воздуха (1 система)</p>, cost: <p>5000</p>, href: 'https://forms.gle/MimnsbZs6eUxUQXg6'},

        {id: 9, name: <p>Оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности установок пожаротушения (1 направление(узел))</p>, cost: <p>4500 каждое последующее направление(узел) + 4000 руб</p>, href: 'https://forms.gle/UvhHJxcXSStK5Q2T7'},

        {id: 10, name: <p>Оценка соответствия монтажа, ремонта, технического обслуживания и работоспособности установок АПС и СОУЭ (до 5 шлейфов (до 100 датчиков))</p>, cost: <p>2500 каждые последующие 5 шлейфов (100 датчиков) + 2000 руб</p>, href: 'https://forms.gle/zcKQ91XJVPj6TUAY9'},

        {id: 11, name: <p>Проведение расчета продуктов горения, удаляемых вытяжной противодымной вентиляцией</p>, cost: <p>5000 руб/помещение</p>, href: 'https://forms.gle/zniPZTkDNsHRbMtbA'},
    ]

    return (
        <div className='services'>
            <p className='servicesTitle'>
                Перечень услуг
            </p>
            <ServicesTable servicesList={allServices} />
        </div>
    )
}

export default Services