import React from "react"
import ServicesFirstLineLeft from "./ServicesFirstLineLeft"
import ServicesFirstLineRight from "./ServicesFirstLineRight"

const ServicesFirstLine: React.FC = () => {
    const equipment = [
        {key: 1, context: 'внутреннего противопожарного водопровода'},
        {key: 2, context: 'наружного противопожарного водоснабжения'},
        {key: 3, context: 'дымоудаления и подпора воздуха'},
        {key: 4, context: 'пожаротушения'},
        {key: 5, context: 'АПС и СОУЭ'}
    ]

    return (
        <div className='servicesFirstLine'>
            <ServicesFirstLineLeft />
            <ServicesFirstLineRight list={equipment} />
        </div>
    )
}

export default ServicesFirstLine