import React from 'react'
import './Menu.css'
import Logo from './Logo'
import List from './List'

interface PropsPhones {
    phones: Array<string>
}

const Top: React.FC<PropsPhones> = (props) => {
    return (
        <div className='topMenu'>
            <Logo />
            <List phones2={props.phones} />
        </div>
    )
}

export default Top