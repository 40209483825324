import React from 'react'
import './Menu.css'
import { NavLink } from 'react-router-dom'

interface MenuItemProps {
    title: string
    state: boolean
    id: number
    onChange: Function
    href: string
}

const MenuItem: React.FC<MenuItemProps> = (props) => {

    let id = String(props.id)

    return (
        <NavLink 
            id={id} 
            className='menuItem' 
            exact 
            to={props.href} 
            activeClassName='menuItemChosen' 
        >
            {props.title}
        </NavLink>
    )
}

export default MenuItem