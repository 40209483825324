import React from "react"
import ListElement from './ListElement'

interface ListOfEquipment {
    list: Array<any>
}

const ServicesFirstLineRight: React.FC<ListOfEquipment> = (props) => {
    return (
        <div className='servicesFirstLineRight'>
            {props.list.map(elem => {
                return <ListElement element={elem} key={elem.key} />
            })}
        </div>
    )
}

export default ServicesFirstLineRight