import React from "react"
import PhoneIcon from '@material-ui/icons/Phone'

interface Phone1Props {
    phone1: string
}

const Phone1: React.FC<Phone1Props> = (props) => {
    return (
        <div className='phones'>
            <PhoneIcon fontSize='small' />
            <p> 
                {props.phone1} 
            </p>
        </div>
    )
}

export default Phone1