import React from "react"

const QuestTitle: React.FC = () => {
    return (
        <p className='questTitle'>
            Остались вопросы?
        </p>
    )
}

export default QuestTitle